<template>
  <div class="add-new-administrator">
    <title-bar
      :submitBtn="editView"
      :submitBtnTitle="$t('messages.administratorUpdate')"
      :title="editView ? $t('messages.administratorUpdate') : $t('messages.administratorDetail')"
      @submitPressed="submitPressed"
    />

    <administrator-form
      v-if="administrator"
      :administratorObject="administrator"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import AdministratorForm from "./components/AdministratorForm.vue";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  components: {
    TitleBar,
    AdministratorForm,
  },
  data() {
    return {
      administrator: null,
      administratorCard: null,
      action: null,
      administratorList: null,
      editView: false,
    };
  },
  mixins: [ResourceUtils],
  created() {
    this.setMode();
    this.load();
  },
  methods: {
    load() {
      const id = this.$route.params.id;
      this.$Users.getResource({ id }).then((response) => {
        this.administrator = response.data;
      });
    },
    setMode() {
      this.editView = this.$route.name === 'EditAdministrator'
    },
    submitPressed() {
      this.action = "update";
    },
  },
};
</script>
